import {
  reactive,
  toRefs,
  onMounted,
  defineComponent,
  inject,
  ref,
  onServerPrefetch,
} from 'vue';
import { headerController } from '@/service/HeaderController';
import CurrencyDto from "@/service/model/header/CurrencyDto";
import PromotionBannerDto from "@/service/model/header/PromotionBannerDto";
import HeaderDto from "@/service/model/header/HeaderDto";
import NotificationDto from "@/service/model/header/NotificationDto";
import Cookies from 'js-cookie';
import { useContext } from "@/Ph";
import Promotion from "@/components/header/pcHeader/promoBanner/Promotion.vue";
import HeaderCustomerDto from "@/service/model/header/HeaderCustomerDto";

export default defineComponent({
  components: {Promotion },
  setup(props) {
    const context = useContext();
    const state = reactive({
      curList: [] as CurrencyDto[],
      currencyDisplay: false,
      defCurrency: {} as CurrencyDto,
      promotionBannerDto: {} as PromotionBannerDto,
      headInfo: {} as HeaderDto,
      headCustomerInfo: {} as HeaderCustomerDto,
      isSignIn: false,
      notificationList: [] as NotificationDto[],
      isShowNotice: false,
      headerShowStockProduct: false,
    });

    // 在这里确保 headInfo 被正确注入
    const headInfo = inject<HeaderDto>('headInfo', {} as HeaderDto);
    state.headInfo = headInfo;
    state.isSignIn = !!state.headInfo.customerId;
    state.defCurrency = state.headInfo.currencyDto || {} as CurrencyDto;
    state.notificationList = [...(state.headInfo.headNotificationList || [])];
    let pStock = "0";
    if (context.isClient) {
      pStock = Cookies.get("pStock");
      state.isShowNotice = state.notificationList.length > 0 && !Cookies.get("isShowNotice");
    }else {
      pStock = context.request.cookies["pStock"];
      state.isShowNotice = state.notificationList.length > 0 && !context.request.cookies["isShowNotice"];
    }
    state.headerShowStockProduct = pStock === "1";

    onServerPrefetch(async () => {
      const curList = await headerController.getAllCurrencies();
      state.curList = curList;
      context.clientData['curList'] = curList;

      state.notificationList = [...(headInfo.headNotificationList || [])];

    });

    onMounted(() => {
      const clientData = context.clientData['curList'];
      state.curList = clientData || [];
    });

    const currencyIsDisplay = (flag: boolean) => {
      state.currencyDisplay = flag;
    };

    const changeCurrency = async (code: string) => {
      await headerController.changeCurrency(code);
      if (context.isClient) {
        let url = window.location.href;
        if (url.includes("?currency=")) {
          url = url.substring(0, url.indexOf("?"));
        } else if (url.includes("&currency=")) {
          url = url.replace(/&currency=[A-Z]{3}/g, "");
        }
        window.location.href = url;
      }
    };

    const getUrlParams = (name: string) => {
      const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, "i");
      const param = window.location.search.substr(1).match(reg);
      return param ? param[2] : null;
    };

    const handleCloseNotice = () => {
      state.isShowNotice = false;
      Cookies.set('isShowNotice', 'false');
    };

    const showStockProduct = async (showStockProduct: string) => {
      await headerController.showStockProduct(showStockProduct);
      if (context.isClient) {
        window.location.reload();
      }
    };

    return {
      ...toRefs(state),
      context,
      currencyIsDisplay,
      changeCurrency,
      getUrlParams,
      handleCloseNotice,
      showStockProduct,
    };
  },
});
