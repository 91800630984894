import axios, {AxiosResponse, Method} from 'axios'
import FormData from 'form-data'
axios.defaults.timeout=180*1000
axios.defaults.baseURL='/bff'

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
	return config;

}, function (error) {
	console.log("<<"+ error)
	// 对请求错误做些什么
	return Promise.reject(error);
});
// 添加响应拦截器
axios.interceptors.response.use(function (response:AxiosResponse) {
	return response;
},function (error) {

	console.log(">>"+ error)
	return Promise.reject(error);
});

class Config{
	baseURL?:string;
	timeout?:number;
	keepAliveAgent?:any
}

let keepAliveAgent:any = null;

export function config(config:Config){
	axios.defaults.baseURL = config.baseURL;
	axios.defaults.timeout=config.timeout;
	keepAliveAgent = config.keepAliveAgent;
}

export const interceptors={
	before:function(method:string,url:string,params:any,body:any,headers:any){
		return {method,url,params,body,headers};
	},
	after:function(res:any,method:string,url:string,params:any,body:any,headers:any){return res},
	error:function(error:any){throw error;}
};

async function ajax(method:string,url:string,params:any,body:any,headers: {}){
	try {

		const bre = interceptors.before(method, url, params, body, headers);
		if(!bre.method){
			return bre;
		}
		method = bre.method;
		url = bre.url;
		params = bre.params;
		body = bre.body;
		headers = bre.headers;
		const state = await axios({method: <Method>method, url: url, params: params, data: body, headers: headers, httpAgent: keepAliveAgent });
		return interceptors.after(state,method, url, params, body, headers);
	}catch (e) {
		interceptors.error(e);
	}
}
//上面是对axios封装,下面是注解编写,使用注解时一个类不能有相同的方法名,一个方法不能有相同的参数名称.未加注解的参数在get请求用在url,没有RequestBody注解的post请求放在body
const  metadata:any = {};
function findDM(target:any){
	let t = target;
	if(t["name"]==undefined){
		t=target.constructor;
	}
	let name = t["name"];
	if(metadata[name]==undefined){
		metadata[name]=[];
	}
	let dm:any=undefined;
	for(const r of metadata[name]){
		if(r["__target"]===t){
			dm=r;
		}
	}
	if(dm==undefined){
		dm={"__target":t}
		metadata[name].push(dm);
	}
	return dm;
}

function defineMetadata(key:any,value:any,target:any,method:string | symbol="default"){
	const dm = findDM(target);
	if(dm[method]==undefined){
		dm[method]={};
	}
	dm[method][key]=value;
}
function getMetadata(key:any,target:any,method:string | symbol="default"){
	const dm = findDM(target);
	if(dm[method]==undefined){
		return undefined;
	}
	return dm[method][key];
}
export function RequestMapping2Class(prefix: string):ClassDecorator {
	return function (target: object) {
		defineMetadata("prefix",prefix,target);
	};
}
export enum RequestMethod {
	GET="GET", HEAD="HEAD", POST="POST", PUT="PUT", PATCH="PATCH", DELETE="DELETE", OPTIONS="OPTIONS", TRACE="TRACE"
}
enum ParameterDecoratorType{
	RequestBody="RequestBody",RequestPart="RequestPart",QueryMap="QueryMap",PathVariable="PathVariable",RequestHeader="RequestHeader",RequestParam="RequestParam"
}
function getArgsName(func:any) {
	const f = func.toString();
	const args = f.match(/\(([^)]*)\)/)[1];// 首先匹配函数括弧里的参数
	if(args=="") return [];
	// 分解参数成数组,去空格和内联注释
	return args.split(",").map((arg:string) => arg.replace(/\/\*.*\*\//, "").trim());
}


export function RequestMapping(url: string,method: RequestMethod=RequestMethod.GET,headers={}):MethodDecorator {
	return function (target: object, propertyKey: string|symbol, descriptor: PropertyDescriptor) {
		const argsName=getArgsName(descriptor.value);//调用函数时需要用到.
		const RequestBody:number[] = <number[]>getMetadata(ParameterDecoratorType.RequestBody, target,propertyKey);
		const PathVariable = getMetadata(ParameterDecoratorType.PathVariable,target,propertyKey);
		const QueryMap:number[] = <number[]>getMetadata(ParameterDecoratorType.QueryMap,target,propertyKey);
		const RequestPart= getMetadata(ParameterDecoratorType.RequestPart,target,propertyKey);
		const RequestHeader = getMetadata(ParameterDecoratorType.RequestHeader,target,propertyKey);
		const RequestParam = getMetadata(ParameterDecoratorType.RequestParam,target,propertyKey);

		//下面都是为了排除方法中不是url的参数.
		const notparam:number[]=[];
		for(const r of [RequestBody,PathVariable,QueryMap,RequestPart,RequestHeader,RequestParam]){
			if(r!=undefined){
				for(const [key, val] of Object.entries(r)){
					notparam.push(<number>val);
				}
			}
		}
		//未加注解的参数
		for(let i=0;i<argsName.length;i++){
			if(notparam.indexOf(i)<0 ){
				console.error(<string>propertyKey + " param is not have Decorator:"+argsName[i]);
			}
		}
		//重定义方法.
		descriptor.value = async function (...param:any[]): Promise<any> {
			if(PathVariable!=undefined){
				for(const [key, val] of Object.entries(PathVariable)){
					url=url.replaceAll("{"+key+"}",param[<number>val]);
				}
			}
			//头信息处理
			const h:any={};
			Object.assign(h,headers);//固定头信息处理
			//参数中的头信息处理
			if(RequestHeader!=undefined){
				for(const [key, val] of Object.entries(RequestHeader)){
					h[key]=param[<number>val];
				}
			}
			let body:any=new FormData();
			//附件处理
			if(RequestPart!=undefined){
				for(const [key, idx] of Object.entries(RequestPart)){
					if(param[<number>idx] instanceof Array){
						for(const v of param[<number>idx] ){
							body.append(key,v);
						}
					}else{
						body.append(key,param[<number>idx]);
					}
				}
			}
			if(RequestBody != undefined){
				body=param[RequestBody[0]];
			}
			const pb:any = {};
			if(RequestParam!=undefined){
				for(const [key, val] of Object.entries(RequestParam)){
					pb[key]=param[<number>val];
				}
			}
			//将对象展开
			if(QueryMap!=undefined){
				for(const idx of QueryMap){
					for(const [key, val] of Object.entries(param[idx])){
						pb[key]=val;
					}
				}
			}
			//参数
			const requestParam:any = {};
			for(const [key, val] of Object.entries(pb)){
				let pvalue=val;
				if(pvalue instanceof Array){
					pvalue=pvalue.join(",");
				}
				if(RequestBody==undefined && (method==RequestMethod.POST || method==RequestMethod.PUT || method==RequestMethod.PATCH)){
					body.append(key,pvalue);
				}else{
					requestParam[key]=pvalue;
				}
			}
			const prefix=getMetadata("prefix",target.constructor);
			if (method === RequestMethod.GET) {
				body = null;
			}
			const rt = await ajax(<string>method,prefix+url,requestParam,body,h);
			return rt.data;
		};
		return descriptor;
	};
}

export const PostMapping=(url:string,headers?:any)=>RequestMapping(url,RequestMethod.POST,headers);
export const GetMapping=(url:string,headers?:any)=>RequestMapping(url,RequestMethod.GET,headers);
export const PutMapping=(url:string,headers?:any)=>RequestMapping(url,RequestMethod.PUT,headers);
export const DeleteMapping=(url:string,headers?:any)=>RequestMapping(url,RequestMethod.DELETE,headers);
export const PatchMapping=(url:string,headers?:any)=>RequestMapping(url,RequestMethod.PATCH,headers);

function wParameterDecorato(name:ParameterDecoratorType,paramName?:string){
	return function (target: object, propertyKey: string| symbol, descriptor:number) {
		let pt:any= <number[]>getMetadata(name,target,propertyKey);
		if(pt==undefined){
			pt=[];
			if(paramName!=undefined){
				pt={};
			}
		}
		if(paramName!=undefined){
			pt[paramName]=descriptor;
		}else{
			pt.push(descriptor);
		}
		defineMetadata(name,pt,target,propertyKey);
	}
}
export const RequestBody=wParameterDecorato(ParameterDecoratorType.RequestBody);
export const RequestPart=(paramName:string)=>wParameterDecorato(ParameterDecoratorType.RequestPart,paramName);
//少用QueryMap,对象展开后容易重名,内容过多会超出url长度限制.且只能展开一层.
export const QueryMap=wParameterDecorato(ParameterDecoratorType.QueryMap);
export const PathVariable=(paramName:string)=>wParameterDecorato(ParameterDecoratorType.PathVariable,paramName);
export const RequestHeader=(paramName:string)=>wParameterDecorato(ParameterDecoratorType.RequestHeader,paramName);
// requestparam 解决get方法传递参数,打版本会被缩写
export const RequestParam=(paramName:string)=>wParameterDecorato(ParameterDecoratorType.RequestParam,paramName);
