<template>
  <div class="navigation clearfix hidden-xs" v-if="shouldShowElement">
    <div class="categoryList"  @mouseover="showAllCategories('0')" @mouseout="hideAllCategories">
      <a href="javascript:;"> {{"header.allCategories".translate()}}</a>
      <div class="categoryNavBox" v-show="isShowAllCate">
        <div v-for="(item, index) in categoryTree" :class="['list'+parseInt(index+1), current == index+1 ? 'category_hover': '' ]"
             @mouseleave="hideAllCategories"
             @mouseenter="showAllCategories(index+1)"
        >
          <a :href="item.catUrl"  @mouseenter="showSecondCate(index + 1)">{{item.category.name.decodeHtml()}}</a>
          <div class="categoryNavShow" v-if="showSecondCateIndex == (index+1)">
            <dl v-for="(row, i) in tempCate.get(item.category.id)">
              <dd v-for="(cell, j) in row">
                <a :href="cell.catUrl">{{cell.category.name.decodeHtml()}}<em class="hot-category" v-if="cell.category.isHot"></em></a>
              </dd>
            </dl>
            <template v-for="c in cateImg" >
              <p class="categoryImg hidden-sm" v-if="c.info2==item.category.id"><a :href="c.info3" :title="c.title"><img :alt="c.title" :src="c.img1"></a></p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <ul class="clearfix fl">
      <li class="">
        <a href="/new-beads-wholesale/1.html" title="New Jewelry Beads and Findings of the Latest 30 Days">{{"header.newArrivals".translate()}}</a>
      </li>
      <li>
        <a href="/best-seller.html" title="Hottest Jewelry Beads and Findings">{{"header.bestSellers".translate()}}</a>
      </li>
      <li class="hidden-sm ">
        <a href="/mix-beads-wholesale/1.html" title="Mixed Beads and Findings by Colors or Shapes">{{"header.mixedProducts".translate()}}</a>
      </li>
      <li class="promotion" v-if="headInfo?.promotionSwitchOpen">
        <a href="/SalesPromotion.html" title="Promotion"></a>
      </li>
      <li>
        <a title="Small Lot" href="/small-lot/1.html">{{"header.smallLot".translate()}}</a>
      </li>
      <li class="trends hidden-sm hidden-ld" @mouseenter="showHoliday(true)" @mouseleave="showHoliday(false)">
        <a title="trends" href="javascript:;">{{"header.trends".translate()}}</a>
        <div class="navBox cls" v-if="holiday && isShowHoliday">
          <dl class="wPopular">
            <dt>What's Popular</dt>
            <dd v-for="(item, index) in holiday['HeadLineBanner']">
              <a :href="item.url" :title="item.tip">
                <img :src="item.imgUrl" :alt="item.tip">
              </a>
            </dd>
          </dl>
          <dl>
            <dt>{{holiday['HeadLineCategory1']['0'].url}}</dt>
            <dd v-for="(item1, index) in holiday['HeadLineText1']">
              <a :href="item1.url" :class="{'Icon_New': item1.promote==1}">
                {{item1.tip}}
                <span class="Icon_hot" v-if="item1.catId==1"></span>
              </a>
            </dd>

          </dl>
          <dl>
            <dt>{{holiday['HeadLineCategory2']['0'].url}}</dt>
            <dd v-for="(item2, index) in holiday['HeadLineText2']">
              <a :href="item2.url" :class="{'Icon_New': item2.promote==1}">
                {{item2.tip}}
                <span class="Icon_hot" v-if="item2.catId==1"></span>
              </a>
            </dd>

          </dl>
          <dl>
            <dt>{{holiday['HeadLineCategory3']['0'].url}}</dt>
            <dd v-for="(item3, index) in holiday['HeadLineText3']">
              <a :href="item3.url" :class="{'Icon_New': item3.promote==1}">
                {{item3.tip}}
                <span class="Icon_hot" v-if="item3.catId==1"></span>
              </a>
            </dd>
          </dl>
          <dl>
            <dt>{{holiday['HeadLineCategory4']['0'].url}}</dt>
            <dd v-for="(item4, index) in holiday['HeadLineText4']">
              <a :href="item4.url" :class="{'Icon_New': item4.promote==1}">
                {{item4.tip}}
                <span class="Icon_hot" v-if="item4.catId==1"></span>
              </a>
            </dd>
          </dl>
        </div>
      </li>
      <li class="weeklyHotListing" style="line-height: initial">
        <a href="/weekly-hot-listing/1.html" title="Weekly Hot Listing"></a>
      </li>
      <li class="boutique hidden-sm">
        <a href="/bontique/1.html" title="Boutique">{{"header.boutique".translate()}}</a>
      </li>
      <li class="hidden-sm"><a title="Diy" href="/theme/diy.html"><img src="@/assets/images/diy.gif" alt="diy"></a></li>
    </ul>
  </div>
</template>

<script lang="ts" src="./Nav.ts"></script>

<style src="./nav.less" scoped lang="less"></style>