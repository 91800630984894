import {GetMapping, PostMapping, RequestMapping2Class, RequestParam} from "@/http";
import AddToCartDto from "@/service/model/cart/AddToCartDto";
import BatchAddToCartDto from "@/service/model/cart/BatchAddToCartDto";
import ShoppingCartResponseDto from "@/service/model/cart/ShoppingCartResponseDto";
import FreightRebateInfoDto from "./model/cart/FreightRebateInfoDto";
import {QueryMap} from "../http";
import ShoppingCartRequestDto from "./model/cart/ShoppingCartRequestDto";
import AddionalFeeInfoDto from "./model/cart/AddionalFeeInfoDto";
import ShoppingCartStatistics from "./model/cart/ShoppingCartStatistics";


@RequestMapping2Class("/cart")
class CartController {
    @PostMapping("/addToCart")
    addToCart(@RequestParam("productId") productId: number, @RequestParam("qty") qty: number):Promise<AddToCartDto>{
        throw 1;
    }
    @PostMapping("/batchAddToCart")
    batchAddToCart(@RequestParam("productIds") productIds: number[]):Promise<BatchAddToCartDto>{
        throw 1;
    }
    @PostMapping("/buyNow")
    buyNow(@RequestParam("productId") productId: number, @RequestParam("qty") qty: number):Promise<AddToCartDto>{
        throw 1;
    }

    @PostMapping("/changeCheck")
    changeCheck(@RequestParam("shoppingCartId") shoppingCartId: number, @RequestParam("isChecked") isChecked: boolean):Promise<AddToCartDto>{
        throw 1;
    }

    @GetMapping("/shoppingCart")
    shoppingCart(@QueryMap list: ShoppingCartRequestDto):Promise<ShoppingCartResponseDto>{throw 1;}

    @GetMapping("/freightRebateInfo")
    freightRebateInfo(@RequestParam("totalWeight") totalWeight: number,@RequestParam("totalVolume") totalVolume: number,
                      @RequestParam("orderTotalAmount") orderTotalAmount: number,@RequestParam("totalPackFee") totalPackFee: number):Promise<FreightRebateInfoDto>{throw 1;}

    @GetMapping("/getAddionalFeeInfo")
    getAddionalFeeInfo(@RequestParam("cartItemId") cartItemId:number):Promise<AddionalFeeInfoDto>{throw 1;}

    @GetMapping("/loadShoppingCartStatistics")
    loadShoppingCartStatistics(@RequestParam("strProductType") strProductType:string,categoryId:number):Promise<ShoppingCartStatistics>{throw 1;}
}

export const cartController = new CartController;