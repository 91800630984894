<template>
  <div class="headerCont hidden-xs">
    <Promotion></Promotion>
    <div class="topWrap">
      <div class="topMenu">
        <div class="inventory fl">
          <span class="title">Inventory Mode</span>
          <span v-if="headerShowStockProduct" @click="showStockProduct('0')" class="icon-control on " ><em></em></span>
          <span v-else @click="showStockProduct('1')" class="icon-control " ><em></em></span>
          <a class="icon-help" href="/HelpCenter/HelpDetail?articleId=146" target="_blank" aria-label="help"></a>
        </div>
        <ul class="subNav">
          <li><a href="/HelpCenter/HelpAsk">{{"header.contactUs".translate()}}</a></li>
          <li><a href="/Delivery-Fee.html">{{"header.shippingFee".translate()}}</a></li>
          <li class="orderTrackLink" v-if="!isSignIn">
            <a rel="nofollow" href="/OrderTracking.html">{{"header.orderTracking".translate()}}</a>
          </li>
          <li class="orderTrackLink" v-if="isSignIn">
            <a rel="nofollow" href="/MyAccount">{{"header.myAccount".translate()}}</a>
          </li>
          <li class="Help"><a rel="nofollow" href="/HelpCenter">{{"header.help".translate()}}</a></li>
          <li class="currency"
              :class="currencyDisplay ? 'choosed' : '' "
              @mouseenter="currencyIsDisplay(true)"
              @mouseleave="currencyIsDisplay(false)"
          >
            <em rel="nofollow" class="clearfix">
              <span class="curIcon fl" :class="defCurrency.code"></span>
              <span class="fl country">{{defCurrency.fullSign}}</span>
            </em>
            <em class="Bg_FFF"></em> <br>
            <client-only>
              <ul class="noLi" v-show="currencyDisplay">
                <li v-for="(item, index) in curList" :key="index" :id="item.id" :code="item.code" @click="changeCurrency(item.code)">
                  <span class="curIcon" :class="item.code"></span><em rel="nofollow">{{item.displayName}}</em>
                </li>
              </ul>
            </client-only>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <template v-if="notificationList.length > 0 && isShowNotice">
    <div class="noticeTip" v-for="(item, index) in notificationList" >
      <a class="closeBtn" href="javascript:void(0)" @click="handleCloseNotice"></a>
      <p>
        <strong class="noticeTitle">{{item.title}}</strong>
        <div v-html="item.contents"></div>
      </p>
    </div>
  </template>
</template>

<script lang="ts" src="./Top.ts"></script>

<style scoped src="./top.less" lang="less"></style>