import {defineComponent, inject, onBeforeMount, reactive, toRefs,} from 'vue';
import PromotionBannerDto from "@/service/model/header/PromotionBannerDto";
import {getScreen, PhScreen} from "@/utils/media";
import HeaderDto from "@/service/model/header/HeaderDto";
import {useContext} from "@/Ph";

export default defineComponent({
    props: {
        pageLocation: Number
    },
    setup(props) {

        const context = useContext()
        const state = reactive({
            day: <any>0,
            hour: <any>0,
            minute: <any>0,
            second: <any>0,
            countdownTime: <any>0,
            promotionBannerDto: <PromotionBannerDto>{},
            bannerStyle: <any>{},
            headInfo: <HeaderDto>{},
            isShowProBanner: false,
        })

        state.headInfo = inject('headInfo')

        // 倒计时
        const setTimeData = () => {
            // 确保 state.countdownTime 是一个数字
            if (isNaN(state.countdownTime) || state.countdownTime < 0) {
                return;
            }

            // 计算天数
            state.day = Math.floor(state.countdownTime / (60 * 60 * 24));
            state.day = state.day < 10 ? "0" + state.day : state.day;

            // 创建基准日期
            const d2021 = new Date("2021-01-01T00:00:00Z"); // 使用 ISO 格式，确保跨浏览器兼容性
            const cur = new Date(d2021.getTime() + state.countdownTime * 1000);

            // 获取时间部分
            state.hour = cur.getUTCHours() < 10 ? "0" + cur.getUTCHours() : cur.getUTCHours();
            state.minute = cur.getUTCMinutes() < 10 ? "0" + cur.getUTCMinutes() : cur.getUTCMinutes();
            state.second = cur.getUTCSeconds() < 10 ? "0" + cur.getUTCSeconds() : cur.getUTCSeconds();

            // 减少倒计时
            state.countdownTime -= 1;

            // 更新显示标志
            if (state.countdownTime > 0) {
                state.isShowProBanner = true;
                setTimeout(setTimeData, 1000);
            } else {
                state.isShowProBanner = false;
            }
        }

        // 获取promotion
        const getPromotionBanner = () => {
            state.promotionBannerDto = state.headInfo.promotionBanner;
            if (!state.promotionBannerDto) {
                return;
            }
            state.countdownTime = state.promotionBannerDto.leastTime;
            // 初始化设置背景
            outputSize();
            if(context.isClient) {
                // 屏幕尺寸改变
                window.addEventListener("resize", outputSize);
            }

            setTimeData();
        }

        // 判断屏幕宽度
        const outputSize = () => {
            switch (getScreen()) {
                case PhScreen.PC:
                    state.bannerStyle = {
                        "background": "url(" + state.promotionBannerDto.img + ") no-repeat center center",
                        "color": state.promotionBannerDto.color
                    };
                    break;
                case PhScreen.PC_SMALL:
                    state.bannerStyle = {
                        "background": "url(" + state.promotionBannerDto.imgSmall + ") no-repeat center center",
                        "color": state.promotionBannerDto.color
                    };
                    break;
                case PhScreen.PAD:
                    state.bannerStyle = {
                        "background": state.promotionBannerDto.background,
                        "color": state.promotionBannerDto.colorSmall
                    };
                    break;
                case PhScreen.PHONE:
                    state.bannerStyle = {
                        "background": state.promotionBannerDto.background,
                        "color": state.promotionBannerDto.colorSmall
                    };
                    break;
                case PhScreen.PHONE_SMALL:
                    state.bannerStyle = {
                        "background": state.promotionBannerDto.background,
                        "color": state.promotionBannerDto.colorSmall
                    };
                    break;
            }
        }

        getPromotionBanner()

        return {
            ...toRefs(state),
            context,
            setTimeData,
            getPromotionBanner
        }
    }
})