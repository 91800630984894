import {reactive, toRefs, defineComponent, ref} from 'vue'
import {headerController} from "@/service/HeaderController";

export default defineComponent({
    props:{
        isShowCamBox: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        // 获取file 的值
        const uploadImg = ref(null)
        const state = reactive({
            // 搜索方式, 错误信息, 输入图片路径
            searchType: <string> 'img',
            errMsg: <string> '',
            imgUrl: <string> '',
            isUpload: <boolean> false
        })

        // 关闭弹窗
        const closeCamBox = () => {
            context.emit('handleClose')
        }
        // 切换搜索方式
        const handleChangeCamWay = (way) => {
            state.searchType = way;
        }


        // 上传图片
        const uploadSearchImg = async () => {
            let file: any = uploadImg.value,
                size: number = file.files[0].size,
                isImg: boolean = file.files[0].type.includes('image');
            if(size > 5 * 1024 * 1024) {
                state.errMsg = 'The size of the image should be less than 5M!';
                return false;
            } else if(!isImg) {
                state.errMsg = 'The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm!';
                return false;
            }
            state.isUpload = true;
            // 调用接口
            let result: any = await headerController.uploadSearchImg(file.files[0]);
            state.isUpload = false;
            if(result.status){
                window.location.href = '/productImageSearch?key=' + result.message;
            } else {
                state.errMsg = result.message === '-1' ?
                    'The image length and width must be greater than or equal 200 pixels and smaller than or equal to 1024pixes.'
                    : 'The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm!';
            }
        }

        // 通过url 搜索
        const uploadSearchImgByUrl = async () =>{
            let imgUrl: any = state.imgUrl,
                type: any = imgUrl.substring(imgUrl.lastIndexOf('.'),imgUrl.length).toLowerCase();
            if(!/\.(gif|jpg|jpeg|png|bmp|webp|tiff|ppm|)$/.test(type)) {
                state.errMsg = 'The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm!'
                return false;
            }
            if(imgUrl.startsWith('http://') || imgUrl.startsWith('https://')){
                state.isUpload = true;
                let result: any = await headerController.uploadSearchImgByUrl(imgUrl);
                state.isUpload = false;
                if(result.status){
                    window.location.href = '/productImageSearch?key=' + result.message;
                } else {
                    state.errMsg = result.message === '-1' ?
                        'The image length and width must be greater than or equal 200 pixels and smaller than or equal to 1024pixes.'
                        : 'The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm!';
                }
            } else {
                state.errMsg = 'The URL doesn\'t refer to an image or the image is not publicly accessible.'
            }
        }

        return{
            ...toRefs(state),
            closeCamBox,
            handleChangeCamWay,
            uploadSearchImg,
            uploadSearchImgByUrl,
            uploadImg
        }
    }
})
